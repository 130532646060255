import { User, useUser as useUserFromSupabase } from '@supabase/auth-helpers-react'
import { useEffect, useState } from 'react'
import { SupabaseUser } from '@centrito/api/nest/auth/domain/schemas'
import { processSupabaseUser } from '@centrito/api/nest/auth/domain/utils'

export const tryProcessSupabaseUser = (user: User) => {
  try {
    return processSupabaseUser(user)
  } catch (error) {
    return user as SupabaseUser
  }
}

// TODO: Deprecate once user_metadata has migrated from legacy to new type
export const useUser = (): SupabaseUser | null => {
  const originalUser = useUserFromSupabase()
  const [user, setUser] = useState<SupabaseUser | null>(null)

  useEffect(() => {
    if (originalUser) {
      try {
        setUser(processSupabaseUser(originalUser))
      } catch (error) {
        console.error(error)
        setUser(originalUser as SupabaseUser)
      }
    } else {
      setUser(null)
    }
  }, [originalUser])

  return user
}
