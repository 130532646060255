import { UserRoleLegacy } from '@centrito/api/nest/auth/domain/schemas'

export function getIsInternalUser(roles: string[]): boolean {
  // TODO: Remove legacy roles
  const internalRolesLegacy = UserRoleLegacy.options.filter(
    (role) => !['SHOPPER', 'MERCHANT', 'ALLIED_STORE'].includes(role),
  )
  return roles.some((role) => {
    if (role.startsWith('INTERNAL')) {
      return true
    } else {
      if (internalRolesLegacy.includes(role as any)) {
        return true
      } else {
        return false
      }
    }
  })
}
