import { z } from 'zod'

export const UserRoleLegacy = z.enum([
  'MERCHANT',
  'CX',
  'CATALOG',
  'ALLIED_STORE',
  'PURCHASES',
  'LOGISTICS',
  'SUPERUSER',
  'ACCOUNTING',
  'COURIER',
  'SALES_ASSISTANT',
  'DISCOUNT',
])

export const UserRole = z.enum([
  'EXTERNAL_ALLIED_STORE',
  'EXTERNAL_MERCHANT',
  'INTERNAL_ACCOUNTING',
  'INTERNAL_CATALOG',
  'INTERNAL_COURIER',
  'INTERNAL_LOGISTICS',
  'INTERNAL_PROCUREMENT',
  'INTERNAL_SUPERUSER',
])
export type UserRole = z.infer<typeof UserRole>
