import { z } from 'zod'

const PHONE_ACCEPTED_COUNTRY_CODES = {
  CO: { code: '57', digits: 10 }, // Colombia
  US: { code: '1', digits: 10 }, // United States
  EC: { code: '593', digits: 9 }, // Ecuador
  VE: { code: '58', digits: 10 }, // Venezuela
  MX: { code: '52', digits: 10 }, // Mexico
  PE: { code: '51', digits: [8, 9] }, // Peru
  PA: { code: '507', digits: 7 }, // Panama
}

export const PhoneData = z
  .union([z.string(), z.object({ phone: z.string() })])
  .transform((val) => (typeof val === 'string' ? { phone: val } : val))
  .superRefine((val, ctx) => {
    const phone = val.phone
    let isValid = false
    for (const { code, digits } of Object.values(PHONE_ACCEPTED_COUNTRY_CODES)) {
      if (phone.startsWith(code)) {
        const remainingLength = phone.length - code.length
        const digitArray = Array.isArray(digits) ? digits : [digits]
        if (digitArray.includes(remainingLength)) {
          isValid = true
          break // Valid phone number found; exit the loop
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Invalid number of digits for country code ${code}: ${phone}`,
          })
          return // Stop further validation
        }
      }
    }
    if (!isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid country code in phone number: ${phone}`,
      })
    }
  })

export type PhoneData = z.infer<typeof PhoneData>
