import { z } from 'zod'
import { UserRoleLegacy } from './user-role'
import { isArray } from 'class-validator'

const IsRegisteredData = z.object({
  merchant: z.boolean(),
  shopper: z.boolean(),
  alliedStore: z.boolean(),
})
export type IsRegisteredData = z.infer<typeof IsRegisteredData>
export type IsRegiseredKey = keyof IsRegisteredData

export const SupabaseUserMetadata = z.object({
  // TODO: Remove pre-process after migrating legacy to new type
  isRegistered: z.preprocess((val) => {
    if (typeof val === 'boolean') {
      return { merchant: false, shopper: val, alliedStore: false }
    }
    if (typeof val === 'object' && val !== null) {
      const registered = val as { merchant?: boolean; shopper?: boolean; alliedStore?: boolean }
      if (registered.merchant === undefined) {
        registered.merchant = false
      }
      if (registered.shopper === undefined) {
        registered.shopper = false
      }
      if (registered.alliedStore === undefined) {
        registered.alliedStore = false
      }
      return registered
    }
    return val
  }, IsRegisteredData),
  role: z.preprocess(
    // TODO: Remove the SHOPPER role deprecation
    (val) => (isArray(val) ? val.filter((role) => role !== 'SHOPPER') : val),
    z.array(UserRoleLegacy),
  ),
})

export type SupabaseUserMetadata = z.infer<typeof SupabaseUserMetadata>
