import type { User } from '@supabase/gotrue-js'
import { type SupabaseUser, SupabaseUserMetadata } from '@centrito/api/nest/auth/domain/schemas'

export const transformSupabaseUser = (user: User): SupabaseUser => {
  const userMetadata = SupabaseUserMetadata.parse(user.user_metadata)
  return {
    ...user,
    user_metadata: userMetadata,
  }
}
