export const AuthPrefix = '/auth'
export const CatalogPrefix = '/catalogo'
export const OrdersPrefix = '/mis-pedidos'
export const ReportsPrefix = '/reportes'
export const MyAccountPrefix = '/mi-cuenta'

const PATHS_MERCHANT = {
  Site: {
    Home: '/',
    Catalog: `${CatalogPrefix}`,
    MyOrders: `${OrdersPrefix}`,
    Reports: `${ReportsPrefix}`,
    TermsAndConditions: '/terminos-y-condiciones',
    FeesTable: '/tabla-de-tarifas',
    MyAccount: `${MyAccountPrefix}`,
  } as const,
  Auth: {
    Home: `${AuthPrefix}`,
    Login: `${AuthPrefix}/iniciar-sesion`,
    CreateProfile: `${AuthPrefix}/crear-perfil`,
  } as const,
  Orders: {
    Order: `${OrdersPrefix}/[orderId]`,
  } as const,
  Catalog: {
    Shared: {
      Home: '/[merchantName]',
      Cart: '/[merchantName]/carrito',
      Product: '/[merchantName]/producto/[productId]/[variantIndex]',
      SearchText: '/[merchantName]/search/[searchTerm]',
      Retail: {
        Home: '/[merchantName]/al-detal',
        Cart: '/[merchantName]/al-detal/carrito',
        Product: '/[merchantName]/al-detal/producto/[productId]/[variantIndex]',
        SearchText: '/[merchantName]/al-detal/search/[searchTerm]',
      },
    },
    Category: {
      Category: `${CatalogPrefix}/categoria`,
      Product: `${CatalogPrefix}/categoria/producto`,
    },
    Product: {
      Edit: `${CatalogPrefix}/producto/editar/[productId]`,
      Create: `${CatalogPrefix}/producto/crear`,
    },
  } as const,
  Reports: {
    Report: `${ReportsPrefix}/[year]/[month]`,
    Order: `${ReportsPrefix}/[year]/[month]/[source]/[orderId]`,
    MerchantOrderItem: `${ReportsPrefix}/[year]/[month]/merchant/[orderId]/[productId]`,
    SamsamOrderItem: `${ReportsPrefix}/[year]/[month]/samsam/[orderId]/[productId]`,
  } as const,
  MyAccount: {
    GeneralInfo: `${MyAccountPrefix}/informacion-general`,
    PaymentMethods: `${MyAccountPrefix}/medios-de-pago`,
    DeliveryMethods: `${MyAccountPrefix}/medios-de-entrega`,
    EditWebsite: `${MyAccountPrefix}/editar-mi-sitio-web`,
  } as const,
}

export default PATHS_MERCHANT
