import isUndefined from 'lodash.isundefined'
import type { AuthTokens } from '@centrito/api/nest/auth/domain/schemas'
import type { StandardContext } from '@centrito/api/nest/context'

export function getAuthTokens(req: StandardContext['req']): AuthTokens | null {
  const accessToken = req?.headers['access_token']?.toString()
  const refreshToken = req?.headers['refresh_token']?.toString()
  const hasAuthHeaders = !isUndefined(accessToken) && !isUndefined(refreshToken)
  return hasAuthHeaders ? { accessToken, refreshToken } : null
}
