import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import { getAuthenticatedData } from '@centrito/app/store/auth'
import type { AuthenticatedData } from '@centrito/app/store/slices/auth/schema'
import { SupabaseUser } from '@centrito/api/nest/auth/domain/schemas'

export const getIsAuthChange = ({
  authChangeUser,
  hasStateInAuthSession,
  stateAuthenticatedData,
}: {
  authChangeUser: SupabaseUser | null
  hasStateInAuthSession: boolean
  stateAuthenticatedData: AuthenticatedData | null
}): boolean => {
  return (
    (hasStateInAuthSession && isNil(authChangeUser)) ||
    (!hasStateInAuthSession && !isNil(authChangeUser)) ||
    (!isNil(authChangeUser) &&
      !isNil(stateAuthenticatedData) &&
      !isEqual(getAuthenticatedData(authChangeUser), stateAuthenticatedData))
  )
}
