import * as Boom from '@hapi/boom'
import { AuthFlow, SupabaseUser } from '@centrito/api/nest/auth/domain/schemas'
import PATHS_MERCHANT from '@centrito/common/pathsMerchant'
import PATHS from '@centrito/common/paths'

export const getRedirectTo = (user: SupabaseUser, authFlow: AuthFlow): string => {
  if (authFlow === 'MERCHANT') {
    const isMerchant = user.user_metadata.role.includes('MERCHANT')
    if (!isMerchant) {
      throw Boom.forbidden('El usuario no tiene acceso al role de MERCHANT')
    }
    const isRegistered = user.user_metadata.isRegistered.merchant
    return isRegistered ? PATHS_MERCHANT.Site.Home : PATHS_MERCHANT.Auth.CreateProfile
  } else {
    const isRegistered = user.user_metadata.isRegistered.shopper
    if (authFlow === 'AUTH') {
      return isRegistered ? PATHS.Site.Home : PATHS.Auth.Register
    } else if (authFlow === 'CHECKOUT') {
      return isRegistered ? PATHS.Checkout.Single : PATHS.Checkout.Register
    } else {
      throw Boom.internal(`Invalid auth flow: ${authFlow}`)
    }
  }
}
